import React from 'react';
import { Jumbotron } from 'react-bootstrap';
import styled from 'styled-components';


export const Jumbo = (props) => (
  <Styles>
    <Jumbotron fluid>
      <div className="text-center" style={{backgroundColor: "rgba(255, 255, 255, 0.9)"}}>
        <h1 style={{color: "#5dc2de", lineHeight: "4rem"}}>{props.title}</h1>
      </div>
    </Jumbotron>
  </Styles>
);

const Styles = styled.div`
  .jumbotron {
    height: 20%;
    color: #efefef;
    position: relative;
    background-image: url(${`/ocean.jpg`});
    background-size: cover;
  }
`;
