import React from 'react'
import { Jumbo } from '../components/jumbotron'
import { Container, Card } from 'react-bootstrap'
import { FaEnvelope, FaPhone, FaLinkedin } from 'react-icons/fa'

import Layout  from '../components/layout'
import SEO from '../components/seo'


const About = () => (
  <Layout>
    <SEO title="O nás" />
    <Jumbo title="O nás"></Jumbo>
    <Container className="mb-4 about-us">
      <h2>Prečo nás navštíviť ?</h2>
      <Card
        style={{ color: '#5dc2de', background: 'white', border: '2px solid #5dc2de'}}
        className="mb-4"
      >
        <Card.Header>OCEAN DENT s.r.o.</Card.Header>
        <Card.Body>
          <Card.Text>
            <a href="tel:+421910399793"><FaPhone/>+421 910 399 793</a>
          </Card.Text>
          <Card.Text>
            <a href="mailto:oceandentkosice@gmail.com"><FaEnvelope/>oceandentkosice<br className="d-none d-md-block d-lg-none"/>@gmail.com</a>
          </Card.Text>
          {/* <Card.Text>
            <a href="https://www.linkedin.com/in/simona-adamec-va%C5%A1%C5%A1ov%C3%A1/" target="_blank" rel="noreferrer"><FaLinkedin/>MDDr. Simona Adamec Vaššová</a>
          </Card.Text> */}
        </Card.Body>
      </Card>
      <p style={{marginTop: '2%'}}>Rozumieme, že väčšina pacientov zápasí so strachom navštíviť zubného lekára, ale náš tím urobí všetko, čo je v jeho silách, aby sa každý počas svojej návštevy u nás cítil príjemne a bezbolestne. Či už teda prídete na preventívne, štandardné alebo kozmetické stomatologické ošetrenie, vždy sa môžete spoľahnúť na pohodové stretnutie.</p>
      <br/>
      <p>Zubné kreslo zvyčajne nie je spojené s kozmetickým ošetrením. Lenže môže byť dôležitou súčasťou snahy o zlepšenie vzhľadu a príliš často sa prehliada. Ľudia, ktorí sa usilujú vyzerať čo najlepšie, sú ochotní investovať čas a peniaze napríklad na injekcie proti vráskam, dermálne plnivá, či plastické operácie. Avšak, keď otvoria ústa, častokrát to vyjde navnivoč.</p>
      <img className="amb w-100 my-4" src={'/IMG_6579.JPG'} alt="amb"></img>
      <p>Pekný plný úsmev dokáže z každého urobiť atraktívnejšieho, úspešnejšieho, mladšieho a predovšetkým zdravšieho. Belší úsmev môže výrazne vylepšiť vzhľad, ako aj zvýšiť sebadôveru. Rovné zuby majú tiež významné zdravotné výhody. Môžu znižovať alebo odstraňovať problémy s čeľusťami a chrápanie, znižujú plak, ktorý sa zhromažďuje v medzerách. Znižujú tým šance na progresiu ochorenia ďasien, ktoré úzko súvisia s ochoreniami srdca a cukrovkou.</p>
      <h2>Ošetrujúci lekár</h2>
      <div className="py-3 about-us__dentist">
        <h5 className="mb-3 p-0">MDDr. Simona Adamec Vaššová</h5>
        <div className="d-md-flex align-items-center">
          <img className="portrait d-flex" src={'/IMG_6582.JPG'} alt="portrait"></img>
          <div>
            <p>Precízne ošetrenie zubov a komplexnú starostlivosť o chrup u nás poskytne stomatologička MDDr. Simona Adamec Vaššová.</p>
            <p>Stomatológiu ukončila v roku 2013 na LF UPJŠ v Košiciach a následne na to pôsobila v renomovaných zubných ambulanciách v Bratislave i Košiciach.</p>
            <p>Pravidelne sa zúčastňuje stomatologických školení na Slovensku a v zahraničí. Špecializuje sa predovšetkým na estetickú stomatológiu a endodonciu dospelých, avšak venuje sa aj ošetreniu detských pacientov.</p>
            <p>Je flexibilná, komunikatívna a empatická voči ľuďom, snaží sa vždy všetko vysvetliť a poskytnúť viac alternatív na riešenie situácie v dutine ústnej. Prvoradé je pre ňu neustále sebazdokonaľovanie a žiarivý úsmev spokojného pacienta. Aktívne ovláda anglický jazyk.</p>
          </div>
        </div>
      </div>
      {/* <h2>Zdravotná sestra</h2>
      <div className="py-3">
        <h5 className="mb-3 p-0">Karolína Potošňáková</h5>
      </div> */}
    </Container>
  </Layout>
)

export default About